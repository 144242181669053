<template>
    <table>
        <thead class="text-sm">
            <th class="font-medium" width="250" align="left" />
            <th class="font-medium" width="150" align="center">
                {{ $t('settings.read') }}
            </th>
            <th class="font-medium" width="150" align="center">
                {{ $t('settings.create') }}
            </th>
            <th class="font-medium" width="150" align="center">
                {{ $t('settings.update') }}
            </th>
            <th class="font-medium" width="150" align="center">
                {{ $t('settings.delete') }}
            </th>
            <th class="font-medium" width="150" align="center">
                {{ $t('settings.archive') }}
            </th>
        </thead>
        <tbody>
            <tr v-for="(permission, key) in permissionsDataProp" :key="key">
                <td class="text-sm">
                    {{ formatPermissionTitle(key) }}
                </td>
                <td align="center">
                    <el-checkbox
                        v-if="permission['read']"
                        :checked="checkStatus(permission, 'read')"
                        :disabled="!userCan('update settings')"
                        @change="update(role.id, permission['read']['id'], $event)"
                    />
                </td>
                <td align="center">
                    <el-checkbox
                        v-if="permission['create']"
                        :checked="checkStatus(permission, 'create')"
                        :disabled="!userCan('update settings')"
                        @change="update(role.id, permission['create']['id'], $event)"
                    />
                </td>
                <td align="center">
                    <el-checkbox
                        v-if="permission['update']"
                        :checked="checkStatus(permission, 'update')"
                        :disabled="!userCan('update settings')"
                        @change="update(role.id, permission['update']['id'], $event)"
                    />
                </td>
                <td align="center">
                    <el-checkbox
                        v-if="permission['delete']"
                        :checked="checkStatus(permission, 'delete')"
                        :disabled="!userCan('update settings')"
                        @change="update(role.id, permission['delete']['id'], $event)"
                    />
                </td>
                <td align="center">
                    <el-checkbox
                        v-if="permission['archive']"
                        :checked="checkStatus(permission, 'archive')"
                        :disabled="!userCan('update settings')"
                        @change="update(role.id, permission['archive']['id'], $event)"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import snakeCase from 'lodash/snakeCase';

export default {
    props: {
        permissionsData: {
            type:    Object,
            default: () => {},
        },
        role: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            roleProp:            {},
            permissionsDataProp: {},
        };
    },

    watch: {
        role() {
            this.roleProp = this.role;
            this.permissionsDataProp = {};
            this.$nextTick(() => {
                this.permissionsDataProp = this.permissionsData;
            });
        },
    },

    methods: {
        update(roleId, permissionId, checkbox) {
            this.$emit('update', { roleId, permissionId, checkbox });
        },

        checkStatus(permission, type) {
            return this.role?.permissionsId?.includes(permission[type].id);
        },

        formatPermissionTitle(key) {
            return this.$t(`settings.${snakeCase(key)}`);
            // this.$t('settings.calendar_events');
            // this.$t('settings.map');
            // this.$t('settings.service_history');
            // this.$t('settings.clients');
            // this.$t('settings.employees');
            // this.$t('settings.invoices');
            // this.$t('settings.salaries');
            // this.$t('settings.rut_files');
            // this.$t('settings.keys');
            // this.$t('settings.absence');
            // this.$t('settings.chat');
            // this.$t('settings.emails');
            // this.$t('settings.services');
            // this.$t('settings.service_categories');
            // this.$t('settings.managers');
            // this.$t('settings.statistics');
            // this.$t('settings.settings');
            // this.$t('settings.help_desk');
            // this.$t('settings.cars');
            // this.$t('settings.web_integrations');
            // this.$t('settings.dashboard');
        },
    },
};
</script>
<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th {
    padding: 10px;
}

td {
    border-top: 1px solid #ddd;
    padding: 12px;
}
</style>
